import dynamic from 'next/dynamic';
import React, { useRef } from 'react';
import { GlobalHeader } from '@/components/Layouts';
const GlobalFooter = dynamic(() => import('@/components/Layouts/GlobalFooter'), {
  ssr: true,
});
import { useConfigProvider } from '@/context/ConfigProvider';
import classNames from 'classnames';
import { AppSettings } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { EventName } from '@/shared/event-types';
import { imgUrl } from '@/shared/utils';
import Image from 'next/image';

interface LayoutProps {
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

const Layout = ({ children, head, showHeader = true }: LayoutProps) => {
  const { isMobile } = useConfigProvider();
  const { t } = useTranslation('common');
  const ref = useRef(null);

  const getNavItems = () => {
    switch (AppSettings.type) {
      case '16':
        return [
          {
            text: t('Solution'),
            path: `/onlyfans-protection`,
            gaName: EventName.header_service,
            dropdownItems: [
              {
                text: t('OnlyFans Content Protection'),
                path: `/onlyfans-protection`,
                gaName: EventName.header_onlyfans_protection,
              },
              {
                text: t('Impersonation Account Removal'),
                path: `/remove-fake-account`,
                gaName: EventName.header_remove_fake_account,
              },
              {
                text: t('Cam Model Protection'),
                path: `/cam-model-protection`,
                gaName: EventName.header_cam_model_protection,
              },
            ],
          },
          {
            text: t('Service'),
            path: `/content-monitoring`,
            gaName: EventName.header_service,
            dropdownItems: [
              {
                text: t('Content Monitoring'),
                path: `/content-monitoring`,
                gaName: EventName.header_onlyfans_protection,
              },
              {
                text: t('DMCA Takedown'),
                path: `/dmca-takedown`,
                gaName: EventName.header_remove_fake_account,
              },
            ],
          },
          {
            text: t('Pricing'),
            path: '/plan',
            gaName: EventName.header_Plan,
          },
          {
            text: t('Resources'),
            path: `/dmca-protection-badge`,
            gaName: EventName.header_tool,
            dropdownItems: [
              {
                text: t('Blog'),
                path: `/blog`,
                gaName: EventName.header_tool,
              },
              {
                text: t('DMCA Badges'),
                path: `/dmca-protection-badge`,
                gaName: EventName.header_tool,
              },
              {
                text: t('Guides'),
                path: `/dmca-protection-badge`,
                gaName: EventName.header_tool,
              },
              {
                text: t('OnlyFans Restricted Words Checker'),
                path: `/onlyfans-restricted-words-checker`,
                gaName: EventName.header_tool,
              },
              {
                text: t('OnlyFans Caption Generator'),
                path: `/onlyfans-caption-generator`,
                gaName: EventName.header_tool,
              },
              {
                text: t('Platform Comparison'),
                path: `/compare`,
                gaName: EventName.header_tool,
              },
            ],
          },
          {
            text: t('Affiliate Program'),
            path: `/affiliate`,
            gaName: EventName.header_affiliate,
          },
        ];

      default:
        [{}];
    }
  };

  return (
    <div className={'layout'}>
      {head}
      <Image
        src={imgUrl('https://res-front.pumpsoul.com/prod-erasa/image/background-icon.webp', 'mid')}
        alt="background"
        width={100}
        priority
        height={100}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -2,
        }}
      />
      <header className={'header'}>
        <GlobalHeader isMobile={isMobile} navItems={[...getNavItems()]} />
      </header>
      <main
        ref={ref}
        style={{
          marginTop: 'calc(var(--pietra-global-header-heigth))',
        }}
        className={classNames('main', {
          mobile: isMobile,
        })}
      >
        <div>{children}</div>
      </main>
      <footer className={'footer'}>
        <GlobalFooter />
      </footer>
    </div>
  );
};
export default Layout;
